import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { OpenSourcePageQuery } from '../../gatsby-graphql';
import PageBanner from '../components/PageBanner';
import RepositoriesGrid from '../components/RepositoriesGrid';
import { IPage } from '../types';

const OpenSource: React.FC<IPage<OpenSourcePageQuery>> = ({
  data: { footerSocialLinkLabel, navigationItem, siteNavigation, githubData },
  pageContext: { locale },
  location: { pathname },
}) => (
  <Layout
    pagePathName={pathname}
    pageTitle={navigationItem?.name as string}
    pageDescription={navigationItem?.seo?.description as string}
    pageImage={navigationItem?.seo?.image?.file?.url as string}
    lang={locale}
    section={navigationItem}
    siteNavigation={siteNavigation}
    footerSocialLabel={footerSocialLinkLabel?.value as string}
  >
    <PageBanner copy={navigationItem?.bannerCopy?.json} />
    <RepositoriesGrid
      repositories={githubData?.data?.viewer?.repositories?.nodes}
    />
  </Layout>
);

export default OpenSource;

export const pageQuery = graphql`
  query OpenSourcePage($locale: String!, $sectionSlug: String!) {
    ...PageInfoFragment
    # Navigation Item Info
    navigationItem: contentfulNavigationItem(
      slug: { eq: $sectionSlug }
      node_locale: { eq: $locale }
    ) {
      ...NavigationSectionInfo
      bannerCopy {
        json
      }
    }
    # Github Data
    ...GithubData
  }
`;
