import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import styled from 'styled-components';
import { colors, DottedBackground, mq } from '../../styles/theme';
import { BaseContainer } from '../baseComponents';

interface IPageBannerProps {
  copy: Document;
}

const PageBanner: React.FC<IPageBannerProps> = ({ copy }) => (
  <Background>
    <BannerContainer>{documentToReactComponents(copy, {})}</BannerContainer>
  </Background>
);

const Background = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 0;
  min-height: 270px;
  ${mq.tablet} {
    padding: 3rem 0;
  }
  ${mq.desktop} {
    min-height: 300px;
    padding: 4rem 0;
  }
  ${DottedBackground}
`;

const BannerContainer = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    background-color: ${colors.surface};
    margin-bottom: 0.75rem;
    font-size: 2rem;
    line-height: 2rem;
    ${mq.desktop} {
      font-size: 3rem;
      line-height: 3rem;
    }
  }
  p {
    background-color: ${colors.surface};
    margin: 0;
  }
`;

export default PageBanner;
