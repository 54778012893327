import React from 'react';
import styled from 'styled-components';
import { GithubDataDataViewerRepositories } from '../../../gatsby-graphql';
import { BaseContainer } from '../baseComponents';
import { borders, colors, fonts, mq, spacing } from '../../styles/theme';
import Date from '../Date';

interface IRepositoriesGridProps {
  repositories: GithubDataDataViewerRepositories['nodes'];
}

const RepositoriesGrid: React.FC<IRepositoriesGridProps> = ({
  repositories,
}) => (
  <BaseContainer>
    <Container>
      {repositories?.map((repository) => (
        <RepositoryContainer
          key={repository?.name as string}
          href={repository?.url as string}
          target="_blank"
        >
          <h2>{repository?.name}</h2>
          <p>{repository?.description}</p>
          <BottomContainer>
            <div>
              <div
                style={{
                  backgroundColor: repository?.primaryLanguage?.color as string,
                }}
              />
              <span>{repository?.primaryLanguage?.name}</span>
            </div>
            <Date date={repository?.updatedAt} locale="en" />
          </BottomContainer>
        </RepositoryContainer>
      ))}
    </Container>
  </BaseContainer>
);

const Container = styled.div`
  display: grid;
  padding-top: ${spacing.sectionPageContent.top.base};
  row-gap: ${spacing.grid.column.gap};
  column-gap: ${spacing.grid.column.gap};
  grid-template-columns: repeat(1fr);
  ${mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mq.desktop} {
    padding-top: ${spacing.sectionPageContent.top.desktop};
    grid-template-columns: repeat(3, 1fr);
  }
`;

const RepositoryContainer = styled.a`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.border.grey};
  color: inherit;
  text-decoration: none;
  padding: 1rem;
  ${borders.radius};
  h2 {
    font-family: ${fonts.sansSerif};
    font-size: 14px;
    text-decoration: inherit;
  }
  & > p {
    flex: 1;
    font-family: ${fonts.sansSerif};
    font-size: 14px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${fonts.sansSerif};
  font-size: 12px;
  color: ${colors.text.grey};
  & > div {
    display: flex;
    align-items: center;
    div {
      height: 10px;
      width: 10px;
      border-radius: 100%;
      margin-right: 0.25rem;
    }
  }
`;

export default RepositoriesGrid;
